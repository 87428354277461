import React from "react";
import growth from "../assets/img/growth.jpg";
import Churchonline from "../assets/img/Churchonline.jpg";
import bpcikids from "../assets/img/bpcikids.jpg";
import Footer from "../components/Footer";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Accordion } from "react-bootstrap";

const welcomeVariant = {
  hidden: {
    x: -1500,
  },
  visible: {
    x: 0,
    transition: { delay: 0.5, type: "spring", stiffness: 10 },
  },
};

const Hero = () => {
  return (
    <React.Fragment>
      <div className="hero">
        <div className="hero-overlay">
          <HeroWrapper>
            <div className="covid19-banner text-center">
              <p
                className="text-white lead font-weight-bold p-3"
                to="covid19-update"
              >
                We are meeting for church service this and every Sunday at 9am.
                {/* <i className='fas fa-chevron-right ml-1'></i> */}
              </p>
            </div>
            <Container>
              <div className="landing-content text-center">
                <motion.h1
                  variants={welcomeVariant}
                  initial="hidden"
                  animate="visible"
                  className="display-3 font-weight-bold hero-header-one text-white"
                >
                  WELCOME TO BPCI
                </motion.h1>
                <h1 className="font-weight-bold mb-3 display-4 hero-header text-white">
                  We're Meeting for church services at our various locations.
                </h1>
                <div className="accordion-wrapper">
                  <Accordion>
                    <Accordion.Toggle
                      className="accordion__toggler mb-4"
                      eventKey="0"
                    >
                      View More
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <div className="text-white m-3 accordion-content">
                        <h4>Meeting Days & Time</h4>
                        <div className="andover mb-3">
                          <p className="lead font-weight-bold">Andover</p>
                          <span>Sunday 09:00 to 11:30</span> <br />
                          <span>
                            Wednesday 00:00 to 01: 00 Online only
                          </span>{" "}
                          <br />
                          <span>Friday 20:30 to 22:00</span>
                        </div>
                        <div className="stoke">
                          <p className="lead font-weight-bold">Stoke</p>
                          <span>Sunday 16:00 to 18:30</span> <br />
                          <span>Wednesday 00:00 to 01: 00 Online only</span>
                          <br />
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </Accordion>
                </div>
              </div>
            </Container>
          </HeroWrapper>
        </div>
      </div>

      {/* What is on */}
      <WhatIsOn className="py-5 mx-3">
        <h4 className="text-center py-4">WHAT'S ON</h4>
        <Row>
          <Col md={4} className="mb-4 text-center">
            <Card>
              <Card.Img variant="top" src={growth} />
              <Card.Body>
                <Card.Title>SMALL GROUPS</Card.Title>
                {/* <Link
                  to='/'
                  className='btn btn-outline-secondary btn-hover-black'>
                  Find Out More
                </Link> */}
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4 text-center">
            <Card>
              <Card.Img variant="top" src={bpcikids} />
              <Card.Body>
                <Card.Title>BPCI KIDS</Card.Title>
                {/* <Link
                  to='/'
                  className='btn btn-outline-secondary btn-hover-black'>
                  Find Out More
                </Link> */}
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4 text-center">
            <Card>
              <Card.Img variant="top" src={Churchonline} />
              <Card.Body>
                <Card.Title>CHURCH ONLINE</Card.Title>
                {/* <Link
                  to='/'
                  className='btn btn-outline-secondary btn-hover-black'>
                  Find Out More
                </Link> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </WhatIsOn>
      <Footer />
    </React.Fragment>
  );
};

export default Hero;

const WhatIsOn = styled.div`
  .card {
    overflow: hidden;
  }

  .card img {
    transition: all 0.5s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

const HeroWrapper = styled.div`
  padding-top: 66px;
  .landing-content {
    padding-top: 100px;
    position: relative;
    min-height: 500px;
  }
  .covid19-banner {
    background: red;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .hero-header {
    letter-spacing: 3px;
  }
  .hero-header-one {
    letter-spacing: 5px;
  }

  .accordion__toggler {
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
    font-weight: bold;
    padding: 6px 14px;
    letter-spacing: 2px;
    border-radius: 5px;
  }

  .accordion-wrapper {
    position: relative;
    z-index: 1000;
  }

  .accordion-content {
    background: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 500px;
  }

  @media (max-width: 600px) {
    .hero-header-one {
      letter-spacing: 3px;
      font-size: 2.5rem;
    }
    .hero-header {
      font-size: 1.8rem;
    }
    .landing-content {
      padding-top: 50px;
    }
  }

  @media (max-width: 360px) {
    .covid19-banner {
      display: none;
    }
    .landing-content {
      padding-top: 100px;
    }
    .btn-hero {
      margin-bottom: 30px;
    }
  }
`;
