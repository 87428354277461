import React from "react";
import styled from "styled-components";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import bpcilogo from "../assets/img/bpcilogo.jpg";

const NavBar = () => {
  return (
    <React.Fragment>
      <Styles>
        <Navbar bg='dark' variant='dark' expand='lg' className='fixed-top'>
          <Container>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Brand href='/' className='m-auto'>
              <img
                className='mr-1'
                src={bpcilogo}
                alt='church logo'
                style={{ width: "40px", borderRadius: "50%" }}
              />{" "}
              BPCI
            </Navbar.Brand>
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='ml-auto'>
                <Nav.Link href='/'>HOME</Nav.Link>
                <Nav.Link href='about'>ABOUT</Nav.Link>
                <Nav.Link href='contact-us'>CONTACT</Nav.Link>
                <Nav.Link href='giving'>GIVING</Nav.Link>
                <Nav.Link href='photo-gallery'>MEDIA</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Styles>
    </React.Fragment>
  );
};

export default NavBar;

const Styles = styled.div`
  @media (max-width: 768px) {
    .navbar-nav {
      padding-top: 40px;
    }
  }
`;
