import React from "react";
import { Route, Switch } from "react-router-dom";
import NavBar from "./components/NavBar";
import Hero from "./pages/Hero";
import Covid19update from "./pages/Covid19update";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Ourbelieve from "./pages/Ourbelieve";
import Giving from "./pages/Giving";
import Connect from "./pages/Connect";
import Smallgroups from "./pages/Smallgroups";
import Kids from "./pages/Kids";
import Privacypolicy from "./pages/Privacypolicy";
import PhotoGallery from "./pages/PhotoGallery";
import Babtism from "./pages/Babtism";
import PageNotFound from "./pages/PageNotFound";

const App = () => {
  return (
    <React.Fragment>
      <NavBar />
      <Switch>
        <Route exact path='/' component={Hero} />
        <Route path='/covid19-update' component={Covid19update} />
        <Route path='/contact-us' component={Contact} />
        <Route path='/about' component={About} />
        <Route path='/what-we-believe' component={Ourbelieve} />
        <Route path='/giving' component={Giving} />
        <Route path='/connect' component={Connect} />
        <Route path='/small-groups' component={Smallgroups} />
        <Route path='/bpci-kids' component={Kids} />
        <Route path='/privacy-policy' component={Privacypolicy} />
        <Route path='/photo-gallery' component={PhotoGallery} />
        <Route path='/babtism' component={Babtism} />
        <Route component={PageNotFound} />
      </Switch>
    </React.Fragment>
  );
};

export default App;
