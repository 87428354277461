import React, { useContext } from "react";
import { ChurchDetailsContext } from "../context/ChurchDetailsContext";
import styled from "styled-components";
import Footer from "../components/Footer";

const Given = () => {
  const { bankDetails } = useContext(ChurchDetailsContext);
  return (
    <React.Fragment>
      <GivenWrapper>
        <div className='giving'>
          <div className='container'>
            <h1 className=' font-weight-bold'>GIVING ONLINE</h1>
          </div>
        </div>
        <div className='container py-5 given-message'>
          <div className='row'>
            <div className='col-md-6'>
              <h3 className='p-4 text-secondary'>Giving and Donations</h3>
              <p className='text-secondary'>
                Thank you for your interest in contributing financially to the
                growth of this ministry for Jesus Christ. We are so thankful for
                your generosity which enables us to bring practical & spiritual
                help across all departments in the church, our community &
                beyond. Breakthrough Prayer Chapel International (BPCI) is a
                faith-based Christian Charity and is wholly dependent upon the
                faithful gifts and tithes of our members and friends. Our
                promise is to take your donation and use it to the best of our
                ability to fund the spread of the Good News of God's gracious
                salvation both locally and globally.
              </p>
              <p className='text-secondary'>
                Giving is a part of our worship to God. It has been said that
                you can tell nearly everything about a person by the way they
                spend their money. Whether you are tithing in obedience to
                Malachi 3:10 or contributing over and above your tithe as an act
                of sacrificial generosity, your money will help us minister
                God’s hope and healing to Breakthrough Prayer Chapel
                International and our missions elsewhere.
              </p>
              <p className='text-secondary'>
                King David said "I will not offer to the Lord my God sacrifices
                that have cost me nothing." 2 Samuel 24:24. In BPCI most people
                give in order to be blessed, as a sign of our gratitude, in
                obedience to God's command, because God is giving and giving is
                God. God gave his only son for us, that through him we will have
                eternal life.
              </p>

              <p className='text-secondary'>
                As a registered charity in England and Wales, we are required by
                the HMRC to capture all our donations to support any gift aid
                applications we may make. When you donate any sums to us either
                during our services or online, we will ask your details for the
                purposes of taxes and compliance. Rest assured, your funds will
                be handled with the utmost integrity and the information you
                supply us through your giving will be kept strictly
                confidential.
              </p>
            </div>
            <div className='col-md-5 offset-md-1'>
              <h5 className='p-2 font-weight-bold text-muted'>GIVING ONLINE</h5>
              <div className='giving-text p-2'>
                <p className='font-weight-bold text-info'>
                  Giving Online In Uk
                </p>
                <p> You can give online via bank and standing order.</p>
                <h4 className='font-weight-bold text-muted mb-3'>
                  BPCI Bank Details
                </h4>
                <div className='d-flex'>
                  <p className='font-weight-bold mr-5 text-muted'>Name:</p>
                  <p className='text-secondary'>{bankDetails.accountName}</p>
                </div>
                <div className='d-flex '>
                  <p className='font-weight-bold mr-5 text-muted'>Bank:</p>
                  <p className='text-secondary'>{bankDetails.bank}</p>
                </div>
                <div className='d-flex'>
                  <p className='font-weight-bold mr-4 text-muted'>Account:</p>
                  <p className='text-secondary'>{bankDetails.accountNumber}</p>
                </div>
                <div className='d-flex'>
                  <p className='font-weight-bold mr-3 text-muted'>Sort code:</p>
                  <p className='text-secondary'>{bankDetails.sortCode}</p>
                </div>
                <p>
                  Please note, when setting up your standing order payment,
                  please make it clear whether the giving is for general church
                  funds or for other projects, like supporting our charities.
                </p>

                <p className='font-weight-bold text-muted text-info'>
                  International
                </p>

                <p>
                  When giving gifts and aids outside of the UK, please credit:
                </p>

                <span className='d-flex'>
                  <p className='font-weight-bold mr-5 text-muted'>Name:</p>
                  <p className='text-secondary'>{bankDetails.accountName}</p>
                </span>
                <span className='d-flex '>
                  <p className='font-weight-bold mr-5 text-muted'>Bank:</p>
                  <p className='text-secondary'>{bankDetails.bank}</p>
                </span>
                <span className='d-flex'>
                  <p className='font-weight-bold mr-4 text-muted'>Account:</p>
                  <p className='text-secondary'>{bankDetails.accountNumber}</p>
                </span>
                <span className='d-flex'>
                  <p className='font-weight-bold mr-3 text-muted'>Sort code:</p>
                  <p className='text-secondary'>{bankDetails.sortCode}</p>
                </span>
                <span className='d-flex'>
                  <p className='font-weight-bold mr-3 text-muted'>IBAN:</p>
                  <p className='text-secondary'>{bankDetails.IBAN}</p>
                </span>
                <span className='d-flex'>
                  <p className='font-weight-bold mr-3 text-muted'>Swift/BIC:</p>
                  <p className='text-secondary'>{bankDetails.Swift}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </GivenWrapper>
      <Footer />
    </React.Fragment>
  );
};

export default Given;

const GivenWrapper = styled.div`
  color: #e5e9e6;
  h1 {
    font-size: 6rem;
  }
  .btn-impact,
  .btn-give-online {
    background: transparent;
    border: 1px solid #fff;
    padding: 8px 20px;
    color: #e5e9e6;
    letter-spacing: 2px;
    &:hover {
      background: #fff;
      color: black;
    }
  }
  .btn-give-online {
    padding: 8px 12px;
  }
  .given-message {
    color: black;
    h5 {
      background: #e5e9e6;
    }
    h3 {
      border-bottom: 1px solid grey;
      border-top: 1px solid grey;
    }
  }
  @media (max-width: 768px) {
    h1 {
      font-size: 4rem;
    }
  }
`;
